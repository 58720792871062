import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link, useLocation } from "react-router-dom";
import NavFloatingMenu from "./NavFloatingMenu";
import ReportTypeModel from "./Reports/ReportTypeModel";
import DownloadModel from "./DownloadModel";
import Config from "../Config";

import { useDispatch, useSelector } from "react-redux";
import { Remove_User } from "../Redux/actions";
import { GoGlobe } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { FaBuilding } from "react-icons/fa";

const Navbar = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const isAdmin = user?.userType == 1 ? 'ADMIN' : 'CUSTOMER';
  const [isNavActive, setIsNavActive] = useState(false);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [openReportTypeModel, setOpenReportTypeModel] = useState(false);
  const [openDownloadModel, setOpenDownloadModel] = useState(false);

  const [isMobileUser, setIsMobileUser] = useState(false);
  const { pathname } = useLocation();


  const dispatch = useDispatch();

  const logout = () => dispatch(Remove_User());
  //console.log("user", user);
  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setIsMobileUser(isMobile);
  }, [window.innerWidth]);

  useEffect(() => {
    setIsNavActive(false);
  }, [pathname]);

  const toggleNavbar = () => setIsNavActive(!isNavActive);

  return (
    <>
      {openReportTypeModel && (
        <ReportTypeModel
          setOpenReportTypeModel={setOpenReportTypeModel}
        // selectedItem={selectedItem}
        // paymentApiData={paymentApiData}

        />
      )}

      {openDownloadModel && (
        <DownloadModel
          setOpenDownloadModel={setOpenDownloadModel}
        // selectedItem={selectedItem}
        // paymentApiData={paymentApiData}

        />
      )}

      <Nav>
        <Container $isNavActive={isNavActive}>
          <Link to="/" className="z-10">
            <div className="flex space-x-2 mt-2 ">
              <Logo src={Images.PMSNewLogo} alt="logo" />

            </div>
          </Link>
          {/* <div className="ml-2"> */}
          {(user?.userType != Config.userType.SUPERADMIN) &&
            <Company className="hidden md:block">{user.companyName}</Company>}
          {/* </div> */}
          <MenuIconComp toggleNavbar={toggleNavbar} />
          <NavLinksComp
            isAdmin={isAdmin}
            isNavActive={isNavActive}
            isUserMenuVisible={isUserMenuVisible}
            setIsUserMenuVisible={setIsUserMenuVisible}
            isMobileUser={isMobileUser}
            logout={logout}
            user={user}
            pathname={pathname}
            setOpenReportTypeModel={setOpenReportTypeModel}
            setOpenDownloadModel={setOpenDownloadModel}
          />
        </Container>
      </Nav>
    </>
  );
};

const NavLinksComp = ({
  isAdmin,
  isNavActive,
  setIsUserMenuVisible,
  isUserMenuVisible,
  isMobileUser,
  logout,
  user,
  pathname,
  setOpenReportTypeModel,
  setOpenDownloadModel
}) => {
  const [showBookingSubLinks, setShowBookingSubLinks] = useState(false);
  const [showBookingSubLinks2, setShowBookingSubLinks2] = useState(false);
  const [showPaymentsSubLinks, setShowPaymentsSubLinks] = useState(false);
  const [showPostsSubLinks, setShowPostsSubLinks] = useState(false);
  const [showCoursesSubLinks, setShowCoursesSubLinks] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <NavLinksWrapper $isNavActive={isNavActive}>
      {(user?.userType != Config.userType.SUPERADMIN) &&
        <NavLink className="text-bold text-lg text-black md:hidden" >{user.companyName}</NavLink>}


      <Link to="/dashboard">
        <NavLink $active={pathname === "/dashboard"}>
          <p>{t("Dashboard")}</p>
        </NavLink>
      </Link>

      {user?.userType == Config.userType.SUPERADMIN &&
        <>
          <Link to="/users">
            <NavLink $active={pathname === "/users"}>
              <p>{t("Users")}</p>
            </NavLink>
          </Link>
          <Link to="/customers">
            <NavLink $active={pathname === "/customers"}>
              <p>{t("Customers")}</p>
            </NavLink>
          </Link>

          <NavLinkDropdown
            className="relative"
            onMouseEnter={() => setShowCoursesSubLinks(true)}
            onMouseLeave={() => setShowCoursesSubLinks(false)}
            onClick={() => setShowCoursesSubLinks(!showCoursesSubLinks)}
          >
            <p>Courses</p>
            <img
              src={Images.Arrow}
              alt="List icon"
              className="-rotate-90 w-3 h-3"
            />
            {showCoursesSubLinks && (
              <SubLinksWrapper>
                <Link
                  to="/courses"
                  className="text-black hover:text-red-500"
                >
                Courses  
                </Link>
                <Link
                  to="/categories"
                  className="text-black hover:text-red-500"
                >
                  Categories
                </Link>
              </SubLinksWrapper>
            )}
          </NavLinkDropdown>
        </>
      }
      {user?.userType == Config.userType.ADMIN &&
        <>
          <Link to="/field-agents">
            <NavLink $active={pathname === "/field-agents"}>
              <p>{t("Field Agents")}</p>
            </NavLink>
          </Link>
          <Link to="/warehouse-manager">
            <NavLink $active={pathname === "/warehouse-manager"}>
              <p>{t("Warehouse Manager")}</p>
            </NavLink>
          </Link>
        </>
      }
      {user?.userType == Config.userType.FIELDAGENT &&
        <>
          <Link to="/farmers">
            <NavLink $active={pathname === "/farmer"}>
              <p>{t("Farmers")}</p>
            </NavLink>
          </Link>
          <Link to="/production-records">
            <NavLink $active={pathname === "/production-records"}>
              <p>{t("Production")}</p>
            </NavLink>
          </Link>
          <Link to="/harvesting">
            <NavLink $active={pathname === "/harvesting"}>
              <p>{t("Harvesting")}</p>
            </NavLink>
          </Link>
          <Link to="/marketing">
            <NavLink $active={pathname === "/marketing"}>

              <p>{t("Marketing")}</p>
            </NavLink>
          </Link>

        </>
      }
      {user?.userType == Config.userType.WAREHOUSEMANAGER &&
        <>
          <Link to="/raw-material">
            <NavLink $active={pathname === "/raw-material"}>
              <p>Raw Material</p>
            </NavLink>
          </Link>
          <Link to="/processes-product">
            <NavLink $active={pathname === "/processes-product"}>
              <p>Processed Product</p>
            </NavLink>
          </Link>
          {/* <Link to="/distribution">
            <NavLink $active={pathname === "/distribution"}>
              <p>Distribution</p>
            </NavLink>
          </Link> */}
        </>
      }
      {!isMobileUser && (
        <DesktopMenu
          setIsUserMenuVisible={setIsUserMenuVisible}
          isUserMenuVisible={isUserMenuVisible}
          logout={logout}
          user={user}
        />
      )}

      {isMobileUser && <MobileMenu logout={logout} user={user} />}
    </NavLinksWrapper>
  );
};

const DesktopMenu = ({
  setIsUserMenuVisible,
  isUserMenuVisible,
  logout,
  user,
}) => (
  <NavLink
    $last={true}
    className="md:h-full md:h-16 md:bg-white hover:bg-white "
    onMouseEnter={() => setIsUserMenuVisible(true)}
    onMouseLeave={() => setIsUserMenuVisible(false)}
    onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}
  >
    <UserImage src={user?.userImage || Images.NurseImage} alt="nurse image" />
    <p className="md:hidden">My Profile</p>
    {isUserMenuVisible && (
      <NavFloatingMenu
        setIsUserMenuVisible={setIsUserMenuVisible}
        logout={logout}
      />
    )}
  </NavLink>
);

const MobileMenu = ({ logout, user }) => (
  <>
    {/* <Link to="/my-profile" className="h-full">
      <NavLink title="My Profile">
        <UserImage
          src={user?.userImage || Images.NurseImage}
          alt="nurse image"
        />
        <p>My Profile</p>
      </NavLink>
    </Link> */}

    <NavLink $last={true} title="logout">
      <Logout onClick={logout}>Logout</Logout>
    </NavLink>
  </>
);

const MenuIconComp = ({ toggleNavbar }) => (
  <MenuIcon onClick={toggleNavbar}>
    <div className="bar one"></div>
    <div className="bar two"></div>
    <div className="bar three"></div>
  </MenuIcon>
);

const Nav = tw.nav`z-40  w-full border-b bg-white navbar fixed top-0 right-0 left-0`;
const Company = tw.div`z-10 hidden md:block  flex flex-row bg-green-500 text-white  md:text-center space-x-1.5 mx-10 py-2 px-3 md:px-0 w-[30%] leading-none  text-xs rounded-full items-center`
const CompanyToggle = tw.div`z-10 block md:hidden flex flex-row bg-green-500 text-white px-4 ml-1  space-x-1.5  py-1.5 leading-none  text-sm rounded-md items-center`
const MenuIcon = tw.div`cursor-pointer z-10 md:hidden`;

const Container = tw.div` 
Container flex items-center justify-between`;

const Logo = tw.img`w-40 z-10`;
const VerticalBorder = tw.div`w-2.5 h-12 bg-gray-300 mt-1`;

const NavLinksWrapper = tw.ul`
${(p) => (p.$isNavActive ? "translate-y-0" : "-translate-y-full")}
fixed  shadow-md top-0 right-0 left-0 flex flex-col bg-white pt-20 transition duration-200 pb-5  md:relative  md:translate-y-0 md:shadow-none md:flex-row   md:bg-transparent md:p-0   md:items-center w-full md:justify-end md:h-full`;

const NavLink = tw.li` 
${(p) => (p.$active ? "md:bg-gray-100 text-gray-900" : "")}
text-sm text-gray-500  hover:text-gray-900 relative flex space-x-1.5 items-center cursor-pointer w-full   py-3    px-4 h-9 rounded-full hover:bg-gray-100  whitespace-nowrap md:w-auto md:h-full ml-1 `;

const NavLinkDropdown = tw.li` 
${(p) => (p.$active ? "md:bg-gray-100 text-gray-900" : "")}
text-sm text-gray-500  hover:text-gray-900 relative flex space-x-1.5 items-center cursor-pointer w-full   py-3    px-4 h-9 rounded-full whitespace-nowrap md:w-auto md:h-full ml-1 `;

const UserImage = tw.img`
w-6 h-6  md:w-8 md:h-8 rounded-full object-cover overflow-hidden bg-gray-100
`;

const Logout = tw.p` 
text-red-500 text-sm cursor-pointer`;

// const SubLinksWrapper = tw.div`flex flex-col absolute bottom-0 bg-white p-2 py-3 text-sm  rounded-md border border-gray-200 shadow-md z-10 transform  translate-y-full  -translate-x-4`;
const SubLinksWrapper = tw.div`flex flex-col space-y-4 absolute bottom-0 bg-white p-4 py-5 rounded-md border border-gray-200 shadow-md z-10 transform translate-y-20 -translate-x-8`;

const SubLinksWrapper2 = tw.div`flex flex-col absolute bottom-0 bg-white p-2 py-3 text-sm  rounded-md border border-gray-200 shadow-md z-10 transform translate-y-full -translate-x-0 w-36`;

export default Navbar;
