import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { PaginationLimit } from "../../Config";

const CompaniesTable = ({
  ApiData,
  setSelectedData,
}) => {
  const [selectedRow,setSelectedRow]=useState(null);
  const [isMobileView,setIsMobileView]=useState(window.innerWidth<768);
  const [copiedIds, setCopiedIds] = useState([]);

  const toggleRow=(index)=>{
    setSelectedRow(selectedRow===index?null:index);
  }
  
  const [forceUpdate,setForceUpdate]=useState(false);
  const forceUpdateHandler=()=>{
    setForceUpdate((prev)=>!prev);
  };
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name:item.name,
        phone:item.phone,
        address:item.address,
        email:item.email,
        updated_on:item.updated_at,
      }));

      setData(tempData);
    }
    const handleResize = () => {
      // Force a re-render when the window is resized
      forceUpdateHandler();
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener when component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ApiData,copiedIds,forceUpdate]);

  
useEffect(() => {
  const handleResize = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);


const columns = React.useMemo(() => {

  if (isMobileView) {
    // Display only "User" column for mobile screens
    return [
      {
        Header: "User",
        accessor: "userData",
         // Unique ID for the mobile version of the "User" column
      },
    ];
  }

  // Columns for larger screens
  return [
    {
      Header: "Name",
      accessor: "name",
       // Unique ID for the mobile version of the "User" column
    },
      {
          Header: "Phone",
          accessor: "phone",
           // Unique ID for the desktop version of the "User" column
        },
        {
          Header: "Address",
          accessor: "address",
           // Unique ID for the desktop version of the "User" column
        },
        {
          Header:"Email",
          accessor:"email",
        },
        {
          Header:"Updated On",
          accessor:"updated_on",
        }
  ];
}, [isMobileView]);

const {
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  pageOptions,
  page,
  state: { pageIndex },
  previousPage,
  nextPage,
  setPageSize,
  canPreviousPage,
  canNextPage,
} = useTable(
  {
    data,
    columns,
  },
  useSortBy,
  usePagination
);

useEffect(() => {
  setPageSize(PaginationLimit);
}, []);

return (
  <>
    <CustomTable {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column,index) => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row,rowIndex) => {
          prepareRow(row);
          return (
            <React.Fragment key={rowIndex}>
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      onClick={() => {
                        if (cellIndex === 0 && window.innerWidth < 768) {
                          toggleRow(rowIndex);
                        }
                      }}
                    >
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
              
              {selectedRow === rowIndex && window.innerWidth < 768 && (
                <Tr>
                  <Td colSpan={columns.length}>
                    {/* {/ Render the details of the selected row here /} */}
                    <ul>
                      <li>
                        <strong>Payment Id:</strong> {data[rowIndex].uniqueId}
                      </li>
                      <li>
                        <strong>Amount:</strong> {data[rowIndex].amount}
                      </li>
                      <li>
                        <strong>Desc:</strong> {data[rowIndex].planDetails}
                      </li>
                      <li>
                        <strong>Status:</strong> {data[rowIndex].status}
                      </li>
                      <li>
                        <strong>Date:</strong> {data[rowIndex].entryDate}
                      </li>
                      <li>
                        <strong>Action:</strong> {data[rowIndex].action}
                      </li>
                   </ul>
                  </Td>
                </Tr>
              )}
            </React.Fragment>
          );
        })}
      </Tbody>
    </CustomTable>
    {/* Card view for mobile screens */}
    {ApiData?.length>0 && (
    <PaginationWrapper>
      <div className="px-2">
        Page{" "}
        <em>
          {pageIndex + 1} of {pageOptions.length}
        </em>
      </div>

      <div className="flex gap-1">
        <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
          <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
        </PrevBtn>
        <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
          <img
            src={Images.Arrow}
            alt="arrow"
            className="w-2 rotate-180 opacity-75"
          />
        </NextBtn>
      </div>
    </PaginationWrapper>
    )}
   </>
);
};

const BasicInfoWrapper = tw.div` items-start md:w-auto mt-2`;
const Row = tw.div`flex space-x-1 items-center text-sm text-gray-500 -ml-0 xl:ml-0`;
const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;
const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Td = tw.td`p-3 text-sm`;
const Btn = tw.button`grid flex items-center justify-center border border-green-300 w-12 h-5 transform transition duration-200 hover:scale-110 hover:shadow-md pb-0.5 text-gray-500`;
const Label=tw.div`flex flex-row gap-4`;
const SuccessBadge = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;

export default CompaniesTable;
