import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Page } from "../../Components/Styles/PageStyles";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";

import Navbar from "../../Components/Navbar";
import Images from "../../Images";
import { Link } from "react-router-dom";
import BookingSummaryComp from "../../Components/Dashboard/BookingSummaryComp";
import axios from "axios";
import Config from "../../Config";
import { gDistrictsList, gRegionsList, gCouncilList } from "../../Config";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";

const DashBoard = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [apidata, setApiData] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const { t, i18n } = useTranslation();

  const initialValues = {
    startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async (values) => {
    const body = new FormData();
    body.append("token", user.id);
    body.append("userType", user.userType);
    body.append("startDate", startDate);
    body.append("endDate", endDate);

    return await axios.post(`${Config.apiUrl}/dashboard`, body, {
      headers: {
        authorization: user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const refetchInterval = 1000 * 60 * 10; //10 minutes

  const onSuccess = (data) => {};

  //--

  const onError = (error) => console.log("error", error.response);

  const {
    isLoading: isLoading,
    mutate: getDashboardDataMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: onSuccess,
    onError: onError,
  });

  useEffect(() => {
    getDashboardDataMutate();
  }, []);

  // const { isLoading, error, data, refetch } = useQuery(
  //   "dashboardData",
  //   fetchFunction,
  //   {
  //     onSuccess,
  //     onError,
  //   }
  //   );

  const submitHandler = (values) => {
    const newValues = {
      startDateData: moment(values.startDate).format("YYYY-MM-DD"),
      endDateData: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDateData);
    setEndDate(newValues.endDateData);

    getDashboardDataMutate();
  };
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.DashboardBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center right",
        }}
      >
        <Navbar />
        <Page>
          <Container>
            <>
              <Title>
                {t("Welcome")} {user.fname}{" "}
              </Title>

              {/* <p className="text-sm text-gray-400 mt-6"> */}
              {/* {user.userType == 1
                ? "System Admin"
                : user.userType == 3
                ? "Registrar "
                : user.userType == 7
                ? "Regional Registrar "
                : user.userType == 2
                ? "Finance"
                : "Asst Registrar for "}
              {user.userType != 1 &&
              user.userType != 3 &&
              user.userType != 2 &&
              user.userType != 7
                ? gCouncilList[user.region][user.district][user.council - 1]
                    .name +
                  ", " +
                  gDistrictsList[user.region][user.district - 1].name +
                  ", " +
                  gRegionsList[user.region - 1].name
                : user.userType == 7
                ? gRegionsList[user.region - 1].name
                : ""}
              <Filters
                submitHandler={submitHandler}
                initialValues={initialValues}
                InitialLoading={isLoading}
                user={user}
                t={t}
              />
            </p> */}

              <BookingSummaryComp data={data?.data?.data} user={user} />

              {/* <CardWrapper>
              <SingleCard
                title="Application For Registrations"
                bg="bg-orange-500 text-white "
                icon={
                  <img
                    src={Images.ViewList}
                    alt="icon"
                    className="w-16 sm:w-24"
                  />
                }
                link={"/applications"}
              />
              <SingleCard
                title="Changing of Pariculars"
                bg="bg-blue-400 text-white "
                icon={
                  <img
                    src={Images.ViewList}
                    alt="icon"
                    className="w-16 sm:w-24"
                  />
                }
                link={"/other-services"}
              />
              <SingleCard
                title="Payments"
                bg="bg-gray-500 text-white "
                icon={
                  <img
                    src={Images.ViewList}
                    alt="icon"
                    className="w-16 sm:w-24"
                  />
                }
                link={"/applications"}
              />
              <SingleCard
                title="Permission to Change in Name"
                bg="bg-rose-500 text-white "
                icon={
                  <img
                    src={Images.ViewList}
                    alt="icon"
                    className="w-16 sm:w-24"
                  />
                }
                link={"/applications"}
              />
              <SingleCard
                title="Permission to Change in Address"
                bg="bg-teal-500 text-white "
                icon={
                  <img
                    src={Images.ViewList}
                    alt="icon"
                    className="w-16 sm:w-24"
                  />
                }
                link={"/applications"}
              />
            </CardWrapper> */}
            </>
          </Container>
        </Page>
      </Bg>
    </>
  );
};

const Filters = ({ initialValues, submitHandler, InitialLoading, user, t }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs">
            <Label htmlFor="startDate">{t("Start_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <InputGroup className="max-w-xs">
            <Label htmlFor="endDate">{t("End_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Date_filter")}
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const SingleCard = ({ title, bg, icon, link }) => {
  return (
    <Link to={link}>
      <SingleCardWrapper $bg={bg}>
        {icon}
        <p className="text-sm sm:text-base">{title}</p>
      </SingleCardWrapper>
    </Link>
  );
};

const Container = tw.div`Container`;
const Title = tw.h1`text-2xl md:text-3xl text-gray-900 mt-5  `;
const CardWrapper = tw.div`mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 mb-10 `;
const SingleCardWrapper = tw.div`
${(p) => p.$bg}
p-4  h-44 w-32 sm:h-64 sm:w-52 rounded-lg  shadow-lg border border-gray-100 cursor-pointer  transition duration-200 flex flex-col justify-center space-y-5 items-center  hover:scale-95 transform hover:shadow-sm text-center`;
const FilterContainer = tw.div`
grid grid-cols-2 w-full max-w-xl gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-cyan-600 hover:bg-cyan-700  py-2.5 text-sm`;
const Bg = tw.section`h-screen flex  bg-white justify-center items-center`;
export default DashBoard;
