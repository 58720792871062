import React from "react";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ForUs } from "../Config";
import { useTranslation } from "react-i18next";
import Config from "../Config";

const NavFloatingMenu = ({ setIsUserMenuVisible, logout }) => {
  const user = useSelector((state) => state.UserReducer.user);
  const { t, i18n } = useTranslation();

  //console.log("user", user);
  return (
    <Wrapper>
      <Triangle></Triangle>

      <Greeting>Hi {user?.fname || "User"}</Greeting>
      <MenuWrapper>
        <MenuLinks setIsUserMenuVisible={setIsUserMenuVisible} user={user} t={t} />

        <Logout onClick={logout}>{t("Logout")}</Logout>
      </MenuWrapper>
    </Wrapper>
  );
};

const MenuLinks = ({ setIsUserMenuVisible, user, t }) => (
  <>
    <Link to="/profile">
      <MenuItem>{t("my_profile")}</MenuItem>
    </Link>
    {(user.userType == Config.userType.SUPERADMIN) && (<Link to="/companies">
      <MenuItem>{t("Companies")}</MenuItem>
    </Link>)}

    {(user.userType == Config.userType.FIELDAGENT) && (<Link to="/stock-management">
      <MenuItem>{t("Stock Management")}</MenuItem>
    </Link>)}
    {(user.userType == Config.userType.FIELDAGENT) && (<Link to="/transportation">
      <MenuItem>{t("Transportation")}</MenuItem>
    </Link>)}
    {(user.userType == Config.userType.WAREHOUSEMANAGER) && (<Link to="/distribution">
      <MenuItem>{t("Distribution")}</MenuItem>
    </Link>)}
    {(user.userType == Config.userType.WAREHOUSEMANAGER) && (<Link to="/stock-management-selling">
      <MenuItem>{t("Stock Management")}</MenuItem>
    </Link>)}

  </>
);

const Wrapper = tw.div`
p-6  rounded-md bg-white border-2 border-gray-100 w-56  absolute top-14 right-0 shadow-lg z-10 lg:top-14 lg:w-64`;

const Triangle = tw.div`
w-5 h-5 bg-white absolute -top-3 right-5 transform rotate-45  border-l-2 border-t-2 border-gray-100`;

const Greeting = tw.h2`
text-gray-800 text-sm md:text-base mb-5 font-normal truncate`;

const MenuWrapper = tw.div`
flex flex-col gap-2 w-full`;

const MenuItem = tw.p`
${(p) => p.$extraClass}
text-sm w-full relative text-gray-400 border-b border-gray-100 pb-2 hover:text-[#4e752f]  cursor-pointer `;

const Logout = tw.p` 
text-red-500 text-sm cursor-pointer`;

export default NavFloatingMenu;
