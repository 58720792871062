import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import Navbar from "../Components/Navbar";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import {
  FieldWrapper,
  Label,
  InputGroup,
  CombineInputGroup,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";

const AddAdminPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("");
  const InitialValue = {
    name: "",
    phone: "",
    password: "",
  };

  //------- Send Register Request -------
  const AdminPostFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("phone", values.phone);
    body.append("email", values.email);
    body.append("username", values.username);
    body.append("password", values.password);
    body.append("usertype", values.usertype);
    return await axios.post(`${Config.apiUrl}/create-admin`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    if (res?.data?.status == "OK") {
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postRegister } = useMutation(
    AdminPostFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const SubmitHandler = (values) => {
    postRegister({ ...values });
    navigate("/");
  };

  const handleLogin = () => {};

  return (
    <Login>
      <TextWrapperComp
        password={password}
        setPassword={setPassword}
        handleLogin={handleLogin}
        InitialValue={InitialValue}
        SubmitHandler={SubmitHandler}
        t={t}
      />
    </Login>
  );
};

const TextWrapperComp = ({
  password,
  setPassword,
  IsValidPassword,
  handleLogin,
  InitialValue,
  SubmitHandler,
  showOTP,
  setShowOTP,
  t,
}) => (
  <TextWrapper>
    <Container>
      <Center>
        <div className="flex space-x-6"></div>
        <Title>{t("Register Now")}</Title>
        {showOTP && (
          <>
            <OtpSubTitle>{t("sent_otp")}</OtpSubTitle>
            <DefaultOtpTitle>Or Enter default OTP 1111</DefaultOtpTitle>
          </>
        )}
        <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
          {(formikProps) => (
            <>
              <Navbar />
              <Form>
                <FormContainer>
                  {!showOTP && (
                    <>
                      <InputGroup>
                        <Label htmlFor="name">{t("Name")}</Label>
                        <FieldWrapper>
                          <Field
                            name="name"
                            id="name"
                            inputMode="text"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="email">{t("Email")}</Label>
                        <FieldWrapper>
                          <Field
                            name="email"
                            id="email"
                            inputMode="text"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="phone">{t("Mobile Number")}</Label>
                        <FieldWrapper>
                          <Field
                            name="phone"
                            type="number"
                            id="phone"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <div className="-mt-5">
                        <Labels>
                          {t(
                            "This mobile number is your account's unique ID and will be used for future logins."
                          )}
                        </Labels>
                      </div>

                      <InputGroup className="-mt-2">
                        <Label htmlFor="password">{t("Password")}</Label>
                        <FieldWrapper>
                          <Field
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>

                      <InputGroup>
                        <Label htmlFor="username">{t("UserName")}</Label>
                        <FieldWrapper>
                          <Field
                            name="username"
                            type="text"
                            id="username"
                            autoComplete="off"
                            required
                          />
                        </FieldWrapper>
                      </InputGroup>
                      <InputGroup>
                        <Label htmlFor="usertype">UserType</Label>
                        <FieldWrapper $select={true}>
                          <Field
                            required
                            name="usertype"
                            id="usertype"
                            autoComplete="off"
                          >
                            {(props) => (
                              <Select
                                className="w-full h-full pl-2"
                                {...props.field}
                              >
                                <MenuItem value="1">Super-Admin</MenuItem>
                                <MenuItem value="2">Admin</MenuItem>
                                <MenuItem value="3">Field-Agents</MenuItem>
                                <MenuItem value="4">Warehouse-Manager</MenuItem>
                              </Select>
                            )}
                          </Field>
                        </FieldWrapper>
                      </InputGroup>
                    </>
                  )}
                </FormContainer>
                <div className="mt-8">
                  <SubmitBtn type="submit">{t("Submit")}</SubmitBtn>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Center>
    </Container>
  </TextWrapper>
);

const FormContainer = tw.div` grid max-w-sm  mt-5 w-full gap-5 md:gap-5`;
const Login = tw.section`h-screen  flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col justify-center  items-start   `;
const TextWrapper = tw.div`h-full relative md:pl-8 -mt-8 w-64 md:w-auto pr-4 md:pr-0`;
const Title = tw.h1`text-xl sm:text-2xl font-black tracking-wider `;
const OtpSubTitle = tw.h3`mt-2 sm:mt-2 tracking-wider  text-gray-900 text-sm `;
const DefaultOtpTitle = tw.h3`mt-1 sm:mt-1 tracking-wider  text-gray-700 text-sm `;
const Center = tw.div`h-full w-11/12  py-10 mb-5  flex flex-col   `;
const SubmitBtn = tw.button`
text-sm -mt-0  md:w-80 w-56 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-black hover:bg-gray-900 text-white rounded-md shadow-md`;
const Labels = tw.label`text-xs text-gray-500 font-normal`;
export default AddAdminPage;
