import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  return (
    <Model width={`w-11/12 max-w-xl`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title><MdViewHeadline className="mt-1" />View Details</Title>
        {/* <SingleItem name={"Id"} value={selectedData?.id} /> */}

        <SingleItem name={"Quantity Grade A (bags)"} value={selectedData?.quantityRecivedGradeA} />
        <SingleItem name={"Quantity Grade B (bags)"} value={selectedData?.quantityRecivedGradeB} />
        <SingleItem name={"Source"} value={selectedData?.Source} />
        <SingleItem name={"Receiving Date"} value={moment(selectedData?.receivingDate).format("DD-MM-YYYY hh:mm A")} />
        <SingleItem name={"Quantity Issued Grade A (bags)"} value={selectedData?.quantityissuedGradeA} />
        <SingleItem name={"Quantity Issued Grade B (bags)"} value={selectedData?.quantityissuedGradeB} />
        <SingleItem name={"Balance"} value={selectedData?.Balance} />
        <SingleItem name={"Destination"} value={selectedData?.destination} />
        <SingleItem name={"Issued Date"} value={moment(selectedData?.issueDate).format("DD-MM-YYYY hh:mm A")} />

        {/* <SingleItem name={"Updated On"} value={moment(selectedData?.updated_at).format("DD-MM-YYYY hh:mm A")} /> */}
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {<Value>{value}</Value>}
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-56`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

export default ViewDetailModel;
