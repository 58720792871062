import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Select from "react-select";
import Model from "../Model";

const EditHarvestingModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
  data,
}) => {
  //const deviceData = data.find((item) => item.id === deviceId);
  //console.log(selectedData);

  // const cropType = [
  //   { value: "1", label: "Rice" },
  //   { value: "2", label: "Wheat" },
  //   { value: "3", label: "Tea" },
  //   { value: "4", label: "Coffee" },
  // ]
  const InitialValues = {
    farmerName: selectedData?.farmerName,
    date: selectedData?.harvestDate,
    gradea: selectedData?.quantityGradeA,
    gradeb: selectedData?.quantityGradeB,
    harvested: selectedData?.hearvestedQuantity,
    moisture: selectedData?.moisture,
    cropType: selectedData?.cropType,
    id: selectedData?.id,
    farmerId: selectedData?.farmerId,
    productionId: selectedData?.productionId,
    // cropType: cropType.find((option) => option.value === String(selectedData?.cropName)),
    // status: selectedData?.status,
  };
  console.log(InitialValues)
  const SubmitHandler = (values) => editAddUser(values);

  const varietyType = [
    { value: "1", label: "Grade A" },
    { value: "2", label: "Grade B" },

  ]
  const farmersList = data?.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Harvesting Detail</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <>
                  <InputGroup>
                    <Label htmlFor="farmerName">Farmer Name</Label>
                    <FieldWrapper>
                      <Field
                        type="text"
                        name="farmerName"
                        id="farmerName"
                        autoComplete="off"
                        className="truncate"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <InputGroup>
                    <Label htmlFor="cropType">Crop Type</Label>
                    <FieldWrapper>
                      <Field
                        type="text"
                        name="cropType"
                        id="cropType"
                        autoComplete="off"
                        className="truncate"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>
                  {/* <InputGroup>
                    <Label htmlFor="cropType">Crop Type</Label>
                    <FieldWrapper $select={true}>
                      <Field required name="cropType" id="cropType" autoComplete="off"  >
                        {(props) => (
                          <Select
                            className="w-full h-full"
                            // isMulti
                            {...props.field}
                            required
                            options={cropType}

                            // classNamePrefix="select"
                            // onChange={(val) => {
                            //   props.form.setFieldValue("crop", val.map((role)=>role.value))
                            // }}
                            onChange={(selectedOption) => {
                              props.form.setFieldValue("cropType", selectedOption)
                            }
                            }
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </InputGroup> */}
                </>
              </CombineInputGroup>
              {/* <CombineInputGroup> */}
              {/* <InputGroup>
                  <Label htmlFor="harvested">Harvested Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="harvested"
                      id="harvested"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup> */}
              <InputGroup>
                <Label htmlFor="moisture">Moisture</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="moisture"
                    id="moisture"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              {/* </CombineInputGroup> */}

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>


              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="date">Harvested Date</Label>
                <FieldWrapper>
                  <Field
                    type="date"
                    name="date"
                    id="date"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>



            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditHarvestingModel;
