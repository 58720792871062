import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Categories/Table";
import Navbar from "../../Components/Navbar";
import ChangePasswordModal from "../../Components/Admin/ChangePasswordModal";
import { ToastContainer, toast } from "react-toastify";

import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { ForUs } from "../../Config";
import { MdAdd } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import EditUserModel from "../../Components/Users/EditUsersModel";
import AddCategoryModel from "../../Components/Customers/AddCategoryModel";
import EditCustomerModel from "../../Components/Customers/EditCustomerModel";
import ViewDetailModel from "../../Components/Customers/ViewDetail";
import moment from "moment";

const Categories = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openRoleViewModel, setopenRoleViewModel] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: "",
    startDate: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("userType", Config.userType.SUPERADMIN);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    return await axios.post(`${Config.apiUrl}/getcustomers`, body, {
      headers: {
        authorization: user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCustomersSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getCustomersError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getCustomersMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCustomersSuccess,
    onError: getCustomersError,
  });

  useEffect(() => {
    getCustomersMutate();
  }, [getCustomersMutate]);

  // const submitHandler = (values) => {
  //   setFilterValue(values.filterVal);
  //   getCustomersMutate();
  // };
  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    setFilterValue(values.filterVal);
    getCustomersMutate();
  };

  // console.log(data?.data?.data?.userList?.length);
  console.log(data?.data?.data);

  //------- Add Single Entry -------

  const AddCategoryFunction = async (values) => {
    const body = new FormData();
    body.append("category", values.category);
    body.append("id");
    return await axios.post(`${Config.apiUrl}/adduser`, body, {
      headers: {
        authorization: user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddCategorySuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getCustomersMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setOpenUploadModel(false);
  };

  const onAddCategoryError = (data) => {
    console.log(data?.response?.data?.msg);
    setOpenUploadModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: AddCustomerLoading, mutate: postCustomerMutate } =
    useMutation(AddCategoryFunction, {
      onSuccess: onAddCategorySuccess,
      onError: onAddCategoryError,
    });

  //------- Edit Single Entry -------
  const EditCustomerFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("phone", values.phone);
    body.append("email", values.email);
    body.append("status", values.status);
    body.append("id", values.id);

    return await axios.post(`${Config.apiUrl}/update-user`, body, {
      headers: {
        authorization: user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getCustomersMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editCustomerMutate } =
    useMutation(EditCustomerFunction, {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    });

  // change password
  const changePasswordFunction = async (values) => {
    const body = new FormData();
    console.log(values);
    body.append("password", values.password);
    body.append("id", selectedData.id);
    body.append("confirmPassword", values.confirmPassword);
    return await axios.post(Config.apiUrl + "/change-password", body, {
      headers: {
        // authorization: "Bearer" + " " + user.token,
        authorization: user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onChangePasswordSuccess = (data) => {
    console.log("success", data?.data);
    toast.success(data?.data?.msg, "Success");
    setChangePasswordModel(false);
  };

  const onChangePasswordError = (data) => {
    console.log("error", data?.data);
    toast.error(data?.response?.data?.msg, "An Error Occured");
  };

  const { mutate: changePasswordMutate, isLoading: changePasswordLoading } =
    useMutation(changePasswordFunction, {
      onSuccess: onChangePasswordSuccess,
      onError: onChangePasswordError,
    });
  console.log(selectedData);
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        {changePasswordModel && (
          <ChangePasswordModal
            setChangePasswordModel={setChangePasswordModel}
            changePasswordLoading={changePasswordLoading}
            changePasswordMutate={changePasswordMutate}
          />
        )}

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Categories")}</Title>
              <Underline />
            </div>

            <Uploader setOpenUploadModel={setOpenUploadModel} t={t} />
          </div>

          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              filter={filter}
              user={user}
              t={t}
            />
          </div>

          {openUploadModel && (
            <AddCategoryModel
              postCustomerMutate={postCustomerMutate}
              AddCustomerLoading={AddCustomerLoading}
              setOpenUploadModel={setOpenUploadModel}
              selectedData={selectedData}
              data={data?.data?.data}
            />
          )}

          {editUser && (
            <EditCustomerModel
              editCustomerMutate={editCustomerMutate}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {viewDetail && (
            <ViewDetailModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data}
                setopenRoleViewModel={setopenRoleViewModel}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditUser={setEditUser}
                setViewDetail={setViewDetail}
                setChangePasswordModel={setChangePasswordModel}
              />
            </TableWrapper>
          )}

          {/* {isLoading && <Loading />}
          {error && !isLoading && data?.data?.data?.userList == false && (
            <NotFoundModel />
          )} */}
          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.userList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs">
            <Label htmlFor="startDate">{t("Start_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <InputGroup className="max-w-xs">
            <Label htmlFor="endDate">{t("End_Date")}:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Date_filter")}
          </ApplyBtn>

          <InputGroup className="max-w-xs" style={{ flexBasis: "content" }}>
            <Label htmlFor="filter">{t("Search")}</Label>

            <Select
              autoComplete="off"
              className="max-w-xs md:w-36 pl-2 bg-transparent text-sm p-0 border-none"
              style={{ height: "40px" }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                //searchHandler(e.target.value);
              }}
            >
              <MenuItem value="name">By Name</MenuItem>
              <MenuItem value="phone">By Phone</MenuItem>
              <MenuItem value="email">By Email</MenuItem>
              {/* <MenuItem value="2">Pending Payments</MenuItem>
                <MenuItem value="3">InProgress</MenuItem>
                <MenuItem value="4">Approved By Asst</MenuItem>
                <MenuItem value="5">Completed</MenuItem>
                <MenuItem value="6">Certificate Issued</MenuItem>
                <MenuItem value="10">Rejected</MenuItem> */}
            </Select>
          </InputGroup>
          <InputGroup className="max-w-xs">
            <Label htmlFor="filterVal">{t("Value")}</Label>
            <FieldWrapper className="h-10">
              <Field type="text" name="filterVal" id="filterVal" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = ({ setOpenUploadModel, t }) => {
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add Category")}</p>
      </Button>
      {/* <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <HiUpload className="text-white w-5 h-5" /> <p>Upload Bulk Contact</p>
      </Button> */}
    </UploadWrapper>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;

export default Categories;
