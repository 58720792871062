import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Select from "react-select";
import Model from "../Model";

const EditFarmerModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  //const deviceData = data.find((item) => item.id === deviceId);
  //console.log(selectedData);
  const gender = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const InitialValues = {
    quantitybought: selectedData?.quantityBrought,
    gradea: selectedData?.quantityGradeA,
    gradeb: selectedData?.quantityGradeB,
    pricea: selectedData?.priceGradeA,
    priceb: selectedData?.priceGradeB,
    moisture: selectedData?.moistureLevel,
    id: selectedData?.id,
    recvdate: selectedData?.receivingDate,
    cropType: selectedData?.cropType,
  };
  console.log(InitialValues)



  const SubmitHandler = (values) => editAddUser(values);


  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Marketing </Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="cropType" >Crop Type</Label>
                  <FieldWrapper>
                    <Field name="cropType" id="cropType" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
                {/* <InputGroup>
                  <Label htmlFor="quantitybought" >Quantity Bought(in Bags)</Label>
                  <FieldWrapper>
                    <Field name="quantitybought" id="quantitybought" type="number" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup> */}
                <InputGroup>
                  <Label htmlFor="moisture">Moisture</Label>
                  <FieldWrapper>
                    <Field name="moisture" id="moisture" type="number" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="pricea">Price Grade A(Tsh/bag)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="pricea"
                      id="pricea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="priceb">Price Grade B(Tsh/bag)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="priceb"
                      id="priceb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="recvdate">Receiving Date</Label>
                <FieldWrapper>
                  <Field
                    type="date"
                    name="recvdate"
                    id="recvdate"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditFarmerModel;
