import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const AddSubAgentModel = ({
  AddSubAgentLoading,
  postAddUser,
  setOpenUploadModel,
  selectedData,
  data,
  // companyArr,
}) => {
  const [uploadFile, setUploadFile] = useState("");
  const [districtsList, setDistricts] = useState([]);
  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const InitialValues = {
   
  };
console.log(data)
  const SubmitHandler = (values) => {
    //console.log(values.roles);
    // if (!values.roles)
    // {
    //   toast.error("Please select roles for user");
    //   return;
    // }
    postAddUser(values);
  };

  const Roles = [
    { value: "1", label: "Admin" },
    { value: "2", label: "Aggregators" },
    { value: "3", label: "Warehouses" },
    {value:"4",label:"Farmers"}
   
    // { value: "Administrator", label: "Administrator" },
  ];
  const Company=data?.map((item)=>({
    value:item.id,
    label:item.name,
  }))

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Field Agent</Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="fName" >First Name</Label>
                  <FieldWrapper>
                    <Field name="fName" id="fName" autoComplete="off" required/>
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="lName">Last Name</Label>
                  <FieldWrapper>
                    <Field name="lName" id="lName" autoComplete="off" required/>
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
              <InputGroup>
                <Label htmlFor="email">Email</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="phone">Phone Number</Label>
                <FieldWrapper>
                  <Field
                    type="number"
                    onInput={(e) => {
                      let inputValue=e.target.value;
                      //Ensure minimum length of 9
                      if(inputValue.length<9)
                      {
                        setError("Please enter a 9-digit phone number");
                      }
                      else{
                        setError("");
                      }
                      // if (e.target.value.length > e.target.maxLength)
                      // e.target.value = e.target.value.slice(0,e.target.maxLength);
                      //     }}
                      if (inputValue.length > e.target.maxLength) {
                        inputValue = inputValue.slice(0, e.target.maxLength);
                      }
                      e.target.value=inputValue;
                    }}
                     maxlength = {10}
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    className="truncate"
                    
                  />
                </FieldWrapper>
              </InputGroup>
              </CombineInputGroup>
              {error && <ErrorMessage className="error-message">{error}</ErrorMessage>}

              <InputGroup>
                  <Label htmlFor="password">Password</Label>
                  <FieldWrapper>
                    <Field
                      type="password"
                      name="password"
                      id="password"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                <Label htmlFor="roles">Roles</Label>
                <FieldWrapper $select={true}>
                  <Field required name="roles" id="roles" autoComplete="off">
                    {(props) => (
                      <Select
                        className="w-full h-full pl-2"
                        options={Roles}
                     />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>
              
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const ErrorMessage=tw.div`text-xs text-red-500 flex justify-end`;
const InputGroups = tw.div`w-full flex flex-col space-y-1.5`;
export default AddSubAgentModel;
