import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import Summary from "../../Components/DashBoardNew/Summary";

import { useDispatch } from "react-redux";
import { Update_User } from "../../Redux/actions";
import Navbar from "../../Components/Navbar";
import { Field, Form, Formik } from "formik";
import {
  FieldWrapper,
  InputGroup,
  Label,
} from "../../Components/Styles/InputStyles";
import { Page, Underline } from "../../Components/Styles/PageStyles";

import { useParams } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import moment from "moment";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DashBoardNew = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer.user);

  const { id } = useParams();
  const [startDate, setStartDate] = useState(
    localStorage.getItem("startDate") ||
      moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    localStorage.getItem("endDate") || moment(new Date()).format("YYYY-MM-DD")
  );

  const GetDashboard = async (values) => {
    // console.log("data-edit", values);
    const body = new FormData();
    body.append("userType", user?.userType);

    return await axios.post(`${Config.apiUrl}/dashboard`, values, {
      headers: {
        Authorization: user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getDashboardSuccess = (res) => {};

  const getDashboardError = (res) => {};

  const {
    isLoading: getDashboardLoading,
    mutate: getDashboardMutate,
    error,
    data,
  } = useMutation(GetDashboard, {
    onSuccess: getDashboardSuccess,
    onError: getDashboardError,
  });

  useEffect(() => {
    const body = new FormData();
    body.append("userType", user.userType);

    getDashboardMutate(body);
  }, []);
  console.log(data);

  const submitHandler = (values) => {
    const body = new FormData();
    body.append("userType", user.userType);

    getDashboardMutate(body);
  };

  return (
    <>
      <Navbar />
      {/* <Page> */}
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          // height: "100vh",
        }}
        className="h-screen"
      >
        <Container>
          <BoxHead>
            <BoxTitle className="!mb-0 whitespace-nowrap">
              Summary
              <Underline />
            </BoxTitle>
            {/* <Filters
            submitHandler={submitHandler}
            initialValues={initialValues}
            InitialLoading={getDashboardLoading}
            user={user}
          /> */}
          </BoxHead>

          {!getDashboardLoading && data && (
            <>
              <Summary data={data} />
            </>
          )}

          {getDashboardLoading && <Loading />}
        </Container>
      </Bg>
      {/* </Page> */}
    </>
  );
};

const Filters = ({ initialValues, submitHandler, InitialLoading, user }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer className=" -ml-24">
          <InputGroup className="max-w-xs">
            <Label htmlFor="startDate">Start Date:</Label>
            <FieldWrappers className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrappers>
          </InputGroup>

          <InputGroup className="max-w-xs ">
            <Label htmlFor="endDate">End Date:</Label>
            <FieldWrappers className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" className="ml-1" />
            </FieldWrappers>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit" className="mt-6">
            Apply Filter
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const BoxHead = tw.div`flex justify-between items-center mb-10 mx-auto `;
const TableWrapper = tw.div`my-8 mb-5 overflow-y-hidden overflow-x-auto  w-full relative rounded-lg`;
const Container = tw.div`Container`;
const FirstCol = tw.div` w-full   md:block bg-[#ffffff]  border overflow-hidden rounded-lg shadow-md  h-full `;

const Title = tw.h2`
${(p) => (p.$fullWidth ? "flex inline-flex" : "justify-center")}
text-2xl  text-gray-700 font-medium  space-x-2 items-center  mt-16  rounded-md cursor-pointer`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full max-w-xl gap-4 md:flex md:space-x-4   `;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-cyan-600"
    : ""} border whitespace-nowrap  border-gray-200 flex space-x-1.5 mb-2  justify-center items-center text-gray-800 bg-green-600 h-full rounded-md px-6  cursor-pointer hover:bg-green-700  py-2.5 text-sm`;
const BoxTitle = tw.h1`
${(p) => (p.$fullWidth ? "flex inline-flex" : "justify-center")}
text-2xl  text-gray-700 font-medium  space-x-2 items-center  mb-5  rounded-md cursor-pointer`;
export const FieldWrappers = tw.div`
${(p) => (p.$select ? "" : "pl-2  field-wrapper border border-gray-300")}
 relative  rounded-md   w-full  flex items-center`;
const TwoCol = tw.section`   md:flex items-start gap-8 mt-5 h-full`;
const Bg = tw.section` flex  items-center py-24 md:pt-28 pb-14`;
export default DashBoardNew;
