import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import Config from "../../Config";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const AddHarvestingModel = ({
  AddSubAgentLoading,
  postAddUser,
  postSingleEntry,
  setOpenUploadModel,
  selectedData,
  data,
  // companyArr,
}) => {
  const [cropTypes, setCropTypes] = useState([]);
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  // console.log(data)
  const SubmitHandler = (values) => {

    postAddUser(values);
  };

  // const cropType = [
  //   { value: "1", label: "Rice" },
  //   { value: "2", label: "Wheat" },
  //   { value: "3", label: "Tea" },
  //   { value: "4", label: "Coffee" },
  // ]
  // const varietyType = [
  //   { value: "1", label: "Grade A" },
  //   { value: "2", label: "Grade B" },

  // ]
  // const farmersList = data?.map((item) => ({
  //   value: item.id,
  //   label: item.name,
  // }))

  const farmersList = data?.map((item) => ({
    value: item.id,
    label: item.name,
    cropTypes: item.farmerCrop.map((crop) => crop.cropType),
  }));

  const handleFarmerChange = (selectedFarmer) => {
    const selectedFarmerData = farmersList.find(
      (farmer) => farmer.value === selectedFarmer.value
    );

    if (selectedFarmerData) {
      setCropTypes(
        selectedFarmerData.cropTypes.map((cropType, index) => ({
          value: cropType,
          label: cropType,
        }))
      );
    }
  };
  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Harvesting Detail</Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <>
                  <InputGroup>
                    <Label htmlFor="farmerId">Farmers List</Label>
                    <FieldWrapper $select={true}>
                      <Field required name="farmerId" id="farmerId" autoComplete="off"  >
                        {(props) => (
                          <Select
                            className="w-full h-full"

                            required
                            options={farmersList}
                            classNamePrefix="select"

                            // onChange={(val) => {
                            //   props.form.setFieldValue("farmerId", val.value)
                            // }
                            // }
                            onChange={(val) => {
                              props.form.setFieldValue("farmerId", val.value);
                              handleFarmerChange(val);
                            }}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </InputGroup>

                  {/* <InputGroup>
                    <Label htmlFor="crop">Crop Type</Label>
                    <FieldWrapper $select={true}>
                      <Field required name="crop" id="crop" autoComplete="off"  >
                        {(props) => (
                          <Select
                            className="w-full h-full"
                            // isMulti
                            required
                            options={cropType}
                            classNamePrefix="select"
                            // onChange={(val) => {
                            //   props.form.setFieldValue("crop", val.map((role)=>role.value))
                            // }}
                            onChange={(val) => {
                              props.form.setFieldValue("crop", val.value)
                            }
                            }
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </InputGroup> */}
                  <InputGroup>
                    <Label htmlFor="crop">Crop Type</Label>
                    <FieldWrapper $select={true}>
                      <Field required name="crop" id="crop" autoComplete="off">
                        {(props) => (
                          <Select
                            className="w-full h-full"
                            required
                            options={cropTypes}
                            classNamePrefix="select"
                            onChange={(val) => {
                              props.form.setFieldValue("crop", val.value);
                            }}
                          />
                        )}
                      </Field>
                    </FieldWrapper>
                  </InputGroup>
                </>
              </CombineInputGroup>
              {/* <CombineInputGroup> */}
              {/* <InputGroup>
                  <Label htmlFor="harvested">Harvested Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="harvested"
                      id="harvested"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup> */}
              <InputGroup>
                <Label htmlFor="moisture">Moisture</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="moisture"
                    id="moisture"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              {/* </CombineInputGroup> */}

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>


              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="date">Harvested Date</Label>
                <FieldWrapper>
                  <Field
                    type="date"
                    name="date"
                    id="date"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>




            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;
const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center`;
export default AddHarvestingModel;