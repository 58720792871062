import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const AddFarmerModel = ({
  AddSubAgentLoading,
  postFarmerUser,
  postSingleEntry,
  setOpenUploadModel,
  selectedData,
  data,
  // companyArr,
}) => {
  const [uploadFile, setUploadFile] = useState("");
  const [districtsList, setDistricts] = useState([]);
  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };

  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Farmer </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name" >Name</Label>
                <FieldWrapper>
                  <Field name="name" id="name" autoComplete="off" required />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="phone">Phone Number</Label>
                <FieldWrapper>
                  <Field
                    type="number"
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength)
                        e.target.value = e.target.value.slice(0, e.target.maxLength);
                    }}
                    maxlength={9}
                    name="phone"
                    id="phone"
                    autoComplete="off"
                    className="truncate"

                  />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="age">Age</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="age"
                      id="age"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gender">Gender</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="gender" id="gender" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"
                          // isMulti
                          required
                          options={genderOption}
                          classNamePrefix="select"
                          // onChange={(val) => {
                          //   props.form.setFieldValue("roles", val.map((role)=>role.value))
                          // }
                          onChange={(val) => {
                            props.form.setFieldValue("gender", val.value)
                          }
                          }
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="village">Village</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="village"
                      id="village"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="district">District</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="district"
                      id="district"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>

              {/* <InputGroup>
                <Label htmlFor="address">Address</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="address"
                    id="address"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup> */}

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const ErrorMessage = tw.div`text-xs text-red-500 flex justify-end`;
const InputGroups = tw.div`w-full flex flex-col space-y-1.5`;
export default AddFarmerModel;
