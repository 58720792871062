import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const AddFarmerModel = ({
  AddSubAgentLoading,
  postFarmerUser,
  postSingleEntry,
  setOpenUploadModel,
  selectedData,
  data,
  // companyArr,
}) => {
  const [uploadFile, setUploadFile] = useState("");
  const [districtsList, setDistricts] = useState([]);
  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  // 
  const cropList = data?.map((item) => ({
    value: item.cropType,
    label: item.cropType,
  }))
  // 
  console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };

  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Marketing </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              {/* <InputGroup>
                <Label htmlFor="cropType">CropType</Label>
                <FieldWrapper $select={true}>
                  <Field required name="cropType" id="cropType" autoComplete="off"  >
                    {(props) => (
                      <Select
                        className="w-full h-full"

                        required
                        options={cropList}
                        classNamePrefix="select"

                        onChange={(val) => {
                          props.form.setFieldValue("cropType", val.value)
                        }
                        }
                      // onChange={(val) => {
                      //   props.form.setFieldValue("cropType", val.value);
                      //   handleFarmerChange(val);
                      // }}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup> */}

              <CombineInputGroup>
                {/* <InputGroup>
                  <Label htmlFor="quantitybought" >Quantity Bought(in Bags)</Label>
                  <FieldWrapper>
                    <Field name="quantitybought" id="quantitybought" type="number" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup> */}
                <InputGroup>
                  <Label htmlFor="cropType">CropType</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="cropType" id="cropType" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"

                          required
                          options={cropList}
                          classNamePrefix="select"

                          onChange={(val) => {
                            props.form.setFieldValue("cropType", val.value)
                          }
                          }
                        // onChange={(val) => {
                        //   props.form.setFieldValue("cropType", val.value);
                        //   handleFarmerChange(val);
                        // }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="moisture">Moisture</Label>
                  <FieldWrapper>
                    <Field name="moisture" id="moisture" type="number" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Grade A (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Grade B (bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="pricea">Price Grade A(Tsh/bag)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="pricea"
                      id="pricea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="priceb">Price Grade B(Tsh/bag)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="priceb"
                      id="priceb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="recvdate">Receiving Date</Label>
                <FieldWrapper>
                  <Field
                    type="date"
                    name="recvdate"
                    id="recvdate"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const ErrorMessage = tw.div`text-xs text-red-500 flex justify-end`;
const InputGroups = tw.div`w-full flex flex-col space-y-1.5`;
export default AddFarmerModel;
