import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const AddStockModel = ({
  AddSubAgentLoading,
  postFarmerUser,
  postSingleEntry,
  setOpenUploadModel,
  selectedData,
  data,
  // companyArr,
}) => {
  const [uploadFile, setUploadFile] = useState("");
  const [districtsList, setDistricts] = useState([]);
  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };

  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];

  return (
    <Model width={`w-11/12 max-w-xl `} setOpenModel={setOpenUploadModel}>
      <Title>Add Stock Management </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea">Quantity Received Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradea"
                      id="gradea"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gradeb">Quantity Received Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="gradeb"
                      id="gradeb"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="recvdate">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="recvdate"
                      id="recvdate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="source">Source</Label>
                  <FieldWrapper>
                    <Field name="source" id="source" type="text" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>


              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="quantityissuedGradeA">Quantity Issued Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityissuedGradeA"
                      id="quantityissuedGradeA"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="quantityissuedGradeB">Quantity Issued Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="quantityissuedGradeB"
                      id="quantityissuedGradeB"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="balance" >Balance</Label>
                  <FieldWrapper>
                    <Field name="balance" id="balance" type="number" autoComplete="off" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issueddate">Issued Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="issueddate"
                      id="issueddate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="destination" >Destination</Label>
                <FieldWrapper>
                  <Field name="destination" id="destination" type="text" autoComplete="off" required />
                </FieldWrapper>
              </InputGroup>

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const ErrorMessage = tw.div`text-xs text-red-500 flex justify-end`;
const InputGroups = tw.div`w-full flex flex-col space-y-1.5`;
export default AddStockModel;
