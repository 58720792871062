import React, { useEffect, useState } from "react";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import Config from "../../Config";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import Navbar from "../../Components/Navbar";
import {
  SubmitBtn,
  InputGroup,
  FieldWrapper,
  Label,
  CombineInputGroup,
} from "../../Components/Styles/InputStyles";
import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import { useTranslation } from "react-i18next";
import Loading from "../../Components/Loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const EditProduction = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id } = useParams();

  const [isProductionFetched, setIsProductionFetched] = useState(false);

  const EditProduct = async () =>
    await axios.get(`${Config.apiUrl}/get-production/${id}`, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });

  const { data, isLoading: productionLoading } = useQuery(
    "get-production",

    EditProduct,
    {
      enabled: !isProductionFetched,
    }
  );

  console.log(data);

  //   useEffect(() => {
  //     if (data?.data && !isProductionFetched) {
  //       setIsProductionFetched(true);
  //     }
  //   }, [isProductionFetched, data]);

  useEffect(() => {
    if (data?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, data]);

  const EditProductionFunction = async (values) => {
    const body = new FormData();
    body.append("farmerId", values.farmerId);
    body.append("id", id);
    body.append("farmerName", values.farmerName);
    body.append("grade", values.variety);
    body.append("cropType", values.crop);
    body.append("acersPlanted", values.acres);
    body.append("datePlanted", values.date);
    body.append("prodPractice", values.prodPractice);
    body.append("dap", values.dap);
    body.append("urea", values.urea);
    body.append("pest", values.pest);
    body.append("weddingCount", values.weddingCount);

    return await axios.post(`${Config.apiUrl}/update-production`, body, {
      headers: {
        Authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    //refetch();
    if (res?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    //setOpenUploadModel(false);
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postRegister } = useMutation(
    EditProductionFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );

  const SubmitHandler = (values) => {
    postRegister({ ...values });
    navigate("/production-records");
  };
  // const cropType = [
  //   { value: "1", label: "Rice" },
  //   { value: "2", label: "Wheat" },
  //   { value: "3", label: "Tea" },
  //   { value: "4", label: "Coffee" },
  // ];
  const cropTypeForCompany = (companyName) => {
    // Define all crop types
    const allCropTypes = [
      { value: "rice", label: "Rice" },
      { value: "sunFlower", label: "Sun Flower" },
      { value: "maize", label: "Maize" },
      { value: "sorghum", label: "Sorghum" },
      { value: "soyabeans", label: "Soya Beans" },
      { value: "beans", label: "Beans" },
    ];

    // Check the company name
    if (companyName === "Nondo Investment Company Ltd") {
      return allCropTypes.filter(
        (crop) => crop.value === "maize" || crop.value === "sunFlower"
      );
    }
    if (companyName === "Ikuwo General Enterprises Ltd") {
      return allCropTypes.filter((crop) => crop.value === "beans");
    }
    if (companyName === "Kimolo Super Rice Ltd") {
      return allCropTypes.filter(
        (crop) => crop.value === "rice" || crop.value === "sunFlower"
      );
    }
    if (companyName === "Kibaigwa Flour Supplies Ltd") {
      return allCropTypes.filter(
        (crop) =>
          crop.value === "maize" ||
          crop.value === "sunFlower" ||
          crop.value === "sorghum" ||
          crop.value === "soyabeans"
      );
    }

    return allCropTypes;
  };

  const cropType = cropTypeForCompany(user.companyName);
  const varietyType = [
    { value: "1", label: "Grade A" },
    { value: "2", label: "Grade B" },
  ];

  console.log(data?.data?.data?.farmerName);
  // const farmersList = data?.map((item) => ({
  //   value: item.id,
  //   label: item.name,
  // }));
  const InitialValue = {
    id: id,
    farmerId: data?.data?.data?.farmerId,
    farmerName: data?.data?.data?.farmerName,
    variety: data?.data?.data?.grade, // Ensure 'variety' is initialized
    crop: data?.data?.data?.cropType,
    acres: data?.data?.data?.acersPlanted,
    date: data?.data?.data?.datePlanted,
    weddingCount: data?.data?.data?.weddingCount,
    urea: data?.data?.data?.urea,
    dap: data?.data?.data?.dap,
    pest: data?.data?.data?.pest,
    prodPractice: data?.data?.data?.productionPartices,
  };

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        {/* <Page> */}
        <BoxContainer>
          {/* <Container> */}
          {/* <Center> */}
          <div className="flex w-full justify-center items-center">
            <div>
              <Title>Edit Production</Title>
              <Underline />
            </div>
          </div>
          <TextWrapper>
            <Formik
              initialValues={InitialValue}
              onSubmit={SubmitHandler}
              enableReinitialize
            >
              <Form>
                <Wrapper>
                  <InputGroup>
                    <Label htmlFor="farmerName">Farmer Name</Label>
                    <FieldWrapper>
                      <Field
                        type="text"
                        name="farmerName"
                        placeholder="Farmer Name"
                        id="farmerName"
                        autoComplete="off"
                        className="truncate"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="variety">Variety Type</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="variety"
                          placeholder="Variety Type"
                          id="variety"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="crop">Crop Type</Label>
                      <FieldWrapper $select={true}>
                        <Field
                          required
                          name="crop"
                          id="crop"
                          autoComplete="off"
                        >
                          {(props) => (
                            <Select
                              className="w-full h-full"
                              // isMulti
                              required
                              options={cropType}
                              classNamePrefix="select"
                              // onChange={(val) => {
                              //   props.form.setFieldValue("crop", val.map((role)=>role.value))
                              // }}
                              onChange={(val) => {
                                props.form.setFieldValue("crop", val.value);
                              }}
                            />
                          )}
                        </Field>
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  {/* </CombineInputGroup> */}

                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="acres">Acres Planted</Label>
                      <FieldWrapper>
                        <Field
                          type="number"
                          name="acres"
                          placeholder="acres"
                          id="acres"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="date">Planted Date</Label>
                      <FieldWrapper>
                        <Field
                          type="date"
                          name="date"
                          id="date"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="weddingCount">Weeding Count</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="weddingCount"
                          id="weddingCount"
                          placeholder="Weeding Count"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>

                    <InputGroup>
                      <Label htmlFor="urea">Urea</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="urea"
                          placeholder="urea"
                          id="urea"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>
                  <CombineInputGroup>
                    <InputGroup>
                      <Label htmlFor="dap">Dap</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="dap"
                          id="dap"
                          placeholder="DAP"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                    <InputGroup>
                      <Label htmlFor="pest">Pest</Label>
                      <FieldWrapper>
                        <Field
                          type="text"
                          name="pest"
                          id="pest"
                          placeholder="pest"
                          autoComplete="off"
                          className="truncate"
                          required
                        />
                      </FieldWrapper>
                    </InputGroup>
                  </CombineInputGroup>

                  <InputGroup>
                    <Label htmlFor="prodPractice">Production Practice</Label>
                    <FieldWrapper>
                      <Field
                        component="textarea"
                        row="7"
                        type="textarea"
                        name="prodPractice"
                        id="prodPractice"
                        autoComplete="off"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>
                </Wrapper>
                <UpdateBtn type="submit">Update</UpdateBtn>
              </Form>
            </Formik>
            {/* </div> */}
            {/* </Center> */}
            {/* </Container> */}
          </TextWrapper>
        </BoxContainer>
      </Bg>
    </>
  );
};

const FormContainer = tw.div` grid max-w-sm  mt-5 w-full gap-5 md:gap-5`;
const Login = tw.section`h-screen  flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col h-full w-full justify-center  items-start mt-12   `;
const Logo = tw.img`w-16  mb-6`;
const TextWrapper = tw.div`h-full w-full flex justify-center`;
const TextOverlay = tw.section`absolute -right-40 top-0 h-full  `;
const UpdateBtn = tw.button`
text-sm  w-32  md:w-60 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-custom-green hover:bg-green-700 text-white rounded-md shadow-md md:mx-44`;

//const Title = tw.h1`text-xl sm:text-2xl font-light text-gray-800 tracking-wider`;
const VerticalBorder = tw.div`w-0.5 h-14 bg-gray-300 mt-4`;

//const SubTitle = tw.h3`mt-1 sm:mt-2 tracking-wider  text-gray-500 text-sm  `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const SubTitle = tw.h3`mt-1 sm:mt-2 tracking-wider  text-gray-500 text-sm `;
const OtpSubTitle = tw.h3`mt-2 sm:mt-2 tracking-wider  text-gray-900 text-sm `;
const DefaultOtpTitle = tw.h3`mt-1 sm:mt-1 tracking-wider  text-gray-700 text-sm `;

const Button = tw.button` mt-10 h-20 w-96  md:w-72 lg:w-96 px-5   text-gray-800 flex items-center border rounded-md bg-white z-10 loginBtn  `;

const ImageWrapper = tw.div`hidden h-full  md:block md:w-1/2  `;
const SignUpImage = tw.img`h-full w-full object-cover`;

const Center = tw.div`h-full w-full  py-10 mb-5  flex flex-col   `;
const SmallText = tw.p`text-xs font-normal text-gray-400 `;
const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
export default EditProduction;
