import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Select from "react-select";
import Model from "../Model";

const EditRawMaterialModel = ({
  editAddUser,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {
  //const deviceData = data.find((item) => item.id === deviceId);
  //console.log(selectedData);
  const gender = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const InitialValues = {
    gradea: selectedData?.qtyRecivedGradeA,
    gradeb: selectedData?.qtyRecivedGradeB,
    balance: selectedData?.balance,
    source: selectedData?.source,
    date: selectedData?.receivingDate,
    issueDate: selectedData?.issueDate,
    issuegradeb: selectedData?.quantityissuedGradeA,
    issuegradea: selectedData?.quantityissuedGradeB,
    crop: selectedData?.cropType,
    id: selectedData?.id,

  };
  console.log(InitialValues.date)



  const SubmitHandler = (values) => editAddUser(values);


  return (
    <Model width={`w-11/12 max-w-xl`} setOpenModel={setEditUser}>
      <Title>Edit Rawmaterial </Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="crop" >Crop Name</Label>
                <FieldWrapper>
                  <Field name="crop" id="crop" autoComplete="off" type="text" required />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="gradea" >Quantity Received Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field name="gradea" id="gradea" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="gradeb" >Quantity Received Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field name="gradeb" id="gradeb" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="source">Source</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="source"
                      id="source"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>





                <InputGroup>
                  <Label htmlFor="date">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="date"
                      id="date"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="issuegradea" >Quantity Issued Grade A(bags)</Label>
                  <FieldWrapper>
                    <Field name="issuegradea" id="issuegradea" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issuegradeb" >Quantity Issued Grade B(bags)</Label>
                  <FieldWrapper>
                    <Field name="issuegradeb" id="issuegradeb" autoComplete="off" type="number" required />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="balance">Balance</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="balance"
                      id="balance"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="issueDate">Issued Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="issueDate"
                      id="issueDate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditRawMaterialModel;
