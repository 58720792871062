import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const AddTransportationModel = ({
  AddSubAgentLoading,
  postFarmerUser,
  postSingleEntry,
  setOpenUploadModel,
  selectedData,
  data,
  // companyArr,
}) => {
  const [uploadFile, setUploadFile] = useState("");
  const [districtsList, setDistricts] = useState([]);
  const [error, setError] = useState('');
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {

    postFarmerUser(values);
  };

  const genderOption = [
    { value: "1", label: "Male" },
    { value: "2", label: "Female" },
  ];
  const buyerType = [
    { value: "1", label: "Consumer" },
    { value: "2", label: "Trade" },
  ];

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Stock </Title>

      {AddSubAgentLoading && <Loading />}

      {!AddSubAgentLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="quantityProductRecived">Product Received Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="quantityProductRecived"
                      id="quantityProductRecived"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="quantityProductAvailable">Product Available Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="quantityProductAvailable"
                      id="quantityProductAvailable"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="receivingDate">Receiving Date</Label>
                  <FieldWrapper>
                    <Field
                      type="date"
                      name="receivingDate"
                      id="receivingDate"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="quantitySold">Quantity Sold</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="quantitySold"
                      id="quantitySold"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="buyerName">Buyer Name</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="buyerName"
                      id="buyerName"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="gender">Gender</Label>
                  <FieldWrapper $select={true}>
                    <Field required name="gender" id="gender" autoComplete="off"  >
                      {(props) => (
                        <Select
                          className="w-full h-full"
                          // isMulti
                          required
                          options={genderOption}
                          classNamePrefix="select"
                          // onChange={(val) => {
                          //   props.form.setFieldValue("roles", val.map((role)=>role.value))
                          // }
                          onChange={(val) => {
                            props.form.setFieldValue("gender", val.value)
                          }
                          }
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                </InputGroup>

              </CombineInputGroup>

              <InputGroup>
                <Label htmlFor="buyerType">Type of buyer</Label>
                <FieldWrapper $select={true}>
                  <Field required name="buyerType" id="buyerType" autoComplete="off"  >
                    {(props) => (
                      <Select
                        className="w-full h-full"
                        // isMulti
                        required
                        options={buyerType}
                        classNamePrefix="select"
                        // onChange={(val) => {
                        //   props.form.setFieldValue("roles", val.map((role)=>role.value))
                        // }
                        onChange={(val) => {
                          props.form.setFieldValue("buyerType", val.value)
                        }
                        }
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="balance">Balance Quantity</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="balance"
                      id="balance"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>

                <InputGroup>
                  <Label htmlFor="destination">Destination</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="destination"
                      id="destination"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>


            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const ErrorMessage = tw.div`text-xs text-red-500 flex justify-end`;
const InputGroups = tw.div`w-full flex flex-col space-y-1.5`;
export default AddTransportationModel;
