import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Save_User } from "../Redux/actions";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
// import Select from "react-select";
import { toast } from "react-toastify";
import Checkbox from "react-custom-checkbox";
import { BsCheckLg } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import PasswordChecklist from "react-password-checklist";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Navbar from "../Components/Navbar";
import Table from "../Components/Companies/Table";
import {
  Page,
  BoxContainer,
  BoxTitle,
  Underline,
} from "../Components/Styles/PageStyles";
import {
  PreviewBtn,
  OtherBtn,
  FieldWrapper,
  Label,
  InputGroup,
  CombineInputGroup,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";

const CompanyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedData, setSelectedData] = useState({});

  const fetchFunction = async () => {
    const body = new FormData();
    return await axios.post(`${Config.apiUrl}/get-company`, body, {
      headers: {
        // authorization: user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getPaymentsSuccess = (res) => {
    if (res.data.status == "NOK") error = true;
  };

  const getPaymentsError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getPaymentsMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getPaymentsSuccess,
    onError: getPaymentsError,
  });

  useEffect(() => {
    getPaymentsMutate();
  }, []);

  const initialValues = {
    billId: "",
    filterVal: "",
  };

  const submitHandler = (values) => {
    getPaymentsMutate(values);
  };

  console.log(data);

  return (
    <>
      <Navbar />
      <Page>
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Listed Companies")}</Title>
              <Underline />
            </div>
            {/* <Uploader setPaymentLink={setPaymentLink} /> */}
          </div>
          {/* <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              user={user}
              t={t}
            />
          </div> */}
          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data}
                setSelectedData={setSelectedData}
              />
            </TableWrapper>
          )}
          {isLoading || isRefetching}
          {error && !isLoading}
        </BoxContainer>
      </Page>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border   rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-teal-500 hover:bg-teal-600 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-cyan-600 hover:bg-cyan-700  py-2.5 text-sm`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full max-w-xl gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;
const Login = tw.section`h-screen flex  bg-white justify-center items-center`;

export default CompanyPage;
