import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import { Underline, ErrorText } from "../Styles/PageStyles";
import { useTranslation } from "react-i18next";

const BookingSummaryComp = ({ data, user, activeTab }) => {
  //if (user.isAdmin)

  const [summaryData, setSummaryData] = useState({
    total: "Total Campaigns",
    today: "SMS in Today",
    success: "SMS Delivered",
    failed: "SMS Failed",
  });

  const [filteredData, setFilteredData] = useState({});

  const { t, i18n } = useTranslation();


  return (
    <BookingSummary>
      {(user.userType != 2) && <BookingGrid>
        <>
        <Link to="/applications">
        <BoxComp
          value={data?.total}
          title={t('Total_Application')}
          className="bg-cyan-500 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
        </Link>

        <Link to="/applications">
          <BoxComp
            value={data?.pending}
            title={t('Pending_Applications')}
            className="bg-orange-500 text-white"
            cad={true}
            time="(8 am - 12 pm)"
          />
        </Link>

        <Link to="/other-services">
          <BoxComp
            value={data?.others}
            title={t('Other_Services')}
            className="bg-teal-500 text-white"
            cad={true}
            time="(12 pm - 5 pm)"
          />
        </Link>

        {/* <BoxComp
          value={0}
          title={"Payments"}
          className="bg-emerald-600 text-white"
          cad={true}
          time="(5 pm - 10 pm)"
        /> */}
        {user.userType == 1 ?
          <>
          <Link to="/admin/asttregistrars">
            <BoxComp
              value={data?.asstregistrars}
              title={t('Astt_Registrars')}
              className="bg-rose-600 text-white"
              cad={true}
              time="(5 pm - 10 pm)"
            />
          </Link>
          <Link to="/admin/finance">
            <BoxComp
              value={data?.finance}
              title={t('Finance')}
              className="bg-yellow-500 text-white"
              cad={true}
              time="(5 pm - 10 pm)"
            />
          </Link>
          <Link to="/admin/customers">
            <BoxComp
              value={data?.customers}
              title={t('Customers')}
              className="bg-red-400 text-white"
              cad={true}
              time="(5 pm - 10 pm)"
            />
          </Link>
          </>
        :
        ""}
       </>
      </BookingGrid>}

      {(user.userType == 2) && <BookingGrid>
        <BoxComp
          value={data?.totalPayments}
          title={t('TotalTransaction')}
          className="bg-cyan-500 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
       
        <BoxComp
          value={data?.TotalAmount || 0}
          title={t('TotalAmountTransacted')}
          className="bg-cyan-500 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
       
        <BoxComp
          value={data?.pendingPayments}
          title={t('NoofPendingPayments')}
          className="bg-cyan-500 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
      
        <BoxComp
          value={data?.pendingAmount || 0}
          title={t('AmountofPendingPayments')}
          className="bg-cyan-500 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
       
        <BoxComp
          value={data?.todaysTotalPayments}
          title={t('TodayTotalTransaction')}
          className="bg-rose-600 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
      
        <BoxComp
          value={data?.todaysTotalAmount}
          title={t('TodayTotalAmountTransacted')}
          className="bg-rose-600 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
       
        <BoxComp
          value={data?.todaysPendingPayments}
          title={t('TodayPendingPayments')}
          className="bg-rose-600 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
       
        <BoxComp
          value={data?.todaysPendingAmount}
          title={t('TodayPendingAmount')}
          className="bg-rose-600 text-white"
          cad={false}
          time="(8 am - 10 pm)"
        />
        
        </BookingGrid>}
    </BookingSummary>
  );
};

const BoxComp = ({ value, title, className, cad = false, time }) => (
  <Box className={className}>
    <BoxTitle>{title}</BoxTitle>
    {/* <SubTitle>{time}</SubTitle> */}
    <BoxCount>
      {/* {cad && <p className=" text-white font-medium mb-1">$</p>} */}
      <p>{value}</p>{" "}
    </BoxCount>
  </Box>
);

const BookingSummary = tw.div`  mt-10`;
const Title = tw.h1`text-xl  text-gray-800   font-medium`;
const SubTitle = tw.h4`text-sm text-gray-100 mt-1.5`;

const BookingGrid = tw.div`grid grid-cols-2 md:grid-cols-4  mt-5 gap-6 sm:gap-10 lg:gap-16 text-center`;
const Box = tw.div` w-full h-32 md:h-44 grid place-content-center bg-white rounded-lg   shadow-md   `;
const BoxTitle = tw.h3`text-xs sm:text-sm`;
const BoxCount = tw.p`font-medium text-3xl sm:text-4xl   mt-2 flex items-center space-x-1 justify-center`;

export default BookingSummaryComp;
