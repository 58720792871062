import React, { useEffect, useState } from "react";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Save_User, Save_Games_Data } from "../Redux/actions";
import { Link, useNavigate } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import Select from "react-select";
import { toast } from "react-toastify";
import Checkbox from "react-custom-checkbox";
import { BsCheckLg } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import Card from "../Components/Card/LoginCard";
import {
  InputGroup,
  FieldWrapper,
  Label,
} from "../Components/Styles/InputStyles";
import { useTranslation } from "react-i18next";
import Loading from "react-loading";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [selectedLang, setActiveLang] = useState("eng");

  const InitialValue = {
    phone: "",
    password: "",
  };

  //------- Send Login Request -------
  const LoginPostFunction = async (values) => {
    const body = new FormData();
    body.append("mobile", values.mobile);
    body.append("password", values.password);
    return await axios.post(`${Config.apiUrl}/login`, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onSuccess = (res) => {
    console.log(res?.data?.status);
    if (res?.data?.status == "OK") {
      const expireAt = moment()
        .add(Config.sessionExpiredTime, "minutes")
        .valueOf();
      const realData = { ...res.data.data, expireAt };
      dispatch(Save_User(realData));
      navigate("/dashboard");
    } else toast.error(res?.data?.msg || "Error");
  };

  const onError = (res) => {
    toast.error(res?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: isLoadingReg, mutate: postLogin } = useMutation(
    LoginPostFunction,
    {
      onSuccess: onSuccess,
      onError: onError,
    }
  );
  //------- Send Login Request -------

  const SubmitHandler = (values) => {
    if (!values.mobile) {
      return toast.error("Please enter phone number");
    } else if (!values.password) {
      return toast.error("Please enter password");
    }
    postLogin({ ...values });
    // navigate("/companies");
  };

  const handleLogin = () => {};

  const languageChangeHandler = (value) => {
    setActiveLang(value);
    i18n.changeLanguage(value);
  };

  return (
    <Login
      style={{
        backgroundImage: `url(${Images.LoginImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center right",
      }}
    >
      <LanguageWrapper>
        <LangButton
          $active={selectedLang === "swahili"}
          onClick={() => languageChangeHandler("swahili")}
        >
          Swahili
        </LangButton>
        <LangButton
          $active={selectedLang === "eng"}
          onClick={() => languageChangeHandler("eng")}
        >
          English
        </LangButton>
      </LanguageWrapper>
      <TextOverlay>
        <OverlayTitle>
          PANUKA MANAGEMENT <br /> SYSTEM
        </OverlayTitle>
        <OverlayText>
          Streamlining Operations and Enhancing Efficiency with Procurament
          Management System
        </OverlayText>
      </TextOverlay>
      <Card>
        <TextWrapperComp
          handleLogin={handleLogin}
          InitialValue={InitialValue}
          SubmitHandler={SubmitHandler}
          isLoadingReg={isLoadingReg}
          t={t}
        />
      </Card>
    </Login>
  );
};

const TextWrapperComp = ({
  handleLogin,
  InitialValue,
  SubmitHandler,
  t,
  isLoadingReg,
}) => (
  <TextWrapper>
    <Container>
      <Center>
        <Title>{t("LoginWelcome")}</Title>
        <Formik initialValues={InitialValue} onSubmit={SubmitHandler}>
          {(formikProps) => (
            <>
              <Form className="w-full">
                <FormContainer>
                  <InputGroup>
                    <Label htmlFor="mobile">{t("Phone Number")}</Label>
                    <FieldWrapper>
                      <Field
                        name="mobile"
                        id="mobile"
                        autoComplete="off"
                        type="number"
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="password">{t("Password")}</Label>
                    <FieldWrapper>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        autoComplete="off"
                      />
                    </FieldWrapper>
                  </InputGroup>
                  <RowWrapper className="-mb-8 md:mb-0">
                    <Checkbox
                      icon={<BsCheckLg className="text-cyan-600 w-2 h-2" />}
                      name="my-input"
                      onChange={(value) => {}}
                      borderColor="#6B7280"
                      style={{ cursor: "pointer" }}
                      labelStyle={{
                        marginLeft: 5,
                        userSelect: "none",
                        color: "#6B7280",
                        fontSize: ".875rem",
                      }}
                      label={t("rememeber")}
                    />
                  </RowWrapper>
                  <Link
                    className="md:-mt-16 -mt-9 -mr-6 md:mr-0"
                    to="/forgot-password"
                  >
                    <ForgotPassword>{t("ForgotPassword")}</ForgotPassword>
                  </Link>
                </FormContainer>
                <div className="mt-16 md:mt-14 flex">
                  <SubmitBtn type="submit" disabled={isLoadingReg}>
                    {isLoadingReg ? (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    ) : (
                      t("login")
                    )}
                  </SubmitBtn>
                </div>
                {/* <SubmitBtn type="submit" disabled={runCampaignLoading}>
                    {runCampaignLoading && (
                      <Loading
                        color="white"
                        width={20}
                        height={20}
                        noPadding={true}
                      />
                    )}
                    {!runCampaignLoading && "Send"}
                  </SubmitBtn> */}
              </Form>
            </>
          )}
        </Formik>
      </Center>

      {/* <Link className="md:-mt-10 lg:mt-2 mt-2" to="/register">
        <p className="text-sm text-gray-500 -mt-8 ">
          {t("no_account")}{" "}
          <span className="text-gray-500 hover:text-gray-900 font-medium">
            {t("registar_u")}
          </span>
        </p>
      </Link> */}
    </Container>
  </TextWrapper>
);
const TextOverlay = tw.div`hidden lg:block md:absolute md:top-1/2 md:left-0 md:w-full  px-10 transform -translate-y-32 `;
const OverlayTitle = tw.h2` md:text-4xl md:w-10/12 lg:text-5xl lg:w-7/12 text-white md:leading-tight font-bold`;
const OverlayText = tw.p`text-base mt-2 text-white md:w-10/12 lg:w-6/12 mt-5 leading-relaxed`;
const FormContainer = tw.div` grid max-w-sm  mt-5 w-full gap-5 md:gap-5`;
const Login = tw.section`h-screen flex  bg-white justify-center items-center`;
const Container = tw.div`flex flex-col h-full w-full justify-center items-start   `;
const TextWrapper = tw.div`h-full relative md:pl-8 -mt-8 w-64 md:w-auto pr-4 md:pr-0`;
const Title = tw.h1`text-xl sm:text-2xl font-black tracking-wider md:-mt-14 -mt-8`;
const Center = tw.div`h-full w-11/12  py-10 pt-20 mb-5  flex flex-col   `;
const LoginWrapper = tw.div`flex`;
const PageWrapper = tw.div`text-white text-3xl`;
const LanguageWrapper = tw.div`absolute top-5 right-5 flex space-x-2.5 items-center z-50`;
const RowWrapper = tw.div`flex space-x-2.5 items-center z-50 mt-2 mb- justify-between w-full`;
const ForgotPassword = tw.h3`tracking-wider  text-gray-500 text-xs hover:text-gray-900 float-right w-32 md:w-auto`;
const LangButton = tw.button`
${(p) =>
  p.$active
    ? "bg-cyan-600 text-white"
    : "hover:bg-gray-300 bg-gray-200 text-gray-800"}
px-4 py-2 text-xs  cursor-pointer  rounded-full `;
const SubmitBtn = tw.button`
text-sm -mt-8  w-80 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-black hover:bg-gray-900 text-white rounded-md shadow-md`;
export default LoginPage;
